/* Site-wide CSS */

/* Space out content a bit */
body {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}

body h1,h2 {
  margin: 0 15px 5px 15px;
}

h2#demo {
  border-bottom: 1px solid lightgray;
}

/* Styles for the header */
body {
  margin: 0;
}
#header {
  position: relative;
  font-size: 12px;
  font-family: 'Droid Sans',Arial,Helvetica,sans-serif;
  color: #202020;
  border-top: 2px solid transparent;
  border-bottom: 7px solid #17b4e3;
  line-height: normal;
  padding-bottom: 10px;
}

/* App logo */
#logo {
  position: relative;
  margin-top: 4px;
  font-size: 18px;
  display: block;
  margin-bottom: 2px;
}
#header a {
  color: #1E4193;
  text-decoration: none;
}
#logo img {
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  border-right: 1px solid #0BACB6;
  padding-right: 5px;
  height: 81px;
  width: 81px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
#logo .site-name-slogan {
  font-size: 14px;
  position: relative;
  top: 10px;
  left: -2px;
}
#logo .site-name-slogan span {
  display: block;
  font-size: 24px;
  position: relative;
  left: -2px;
}
#logo .site-name-slogan .slogan {
  color: #202020;
  font-size: 12px;
  position: relative;
  left: 1px;
}
#siteNameBox {
  margin-top: 25px;
}
#siteName {
  font-size: 300%;
}
/* End of styles for the header */

/* External link styles */
a.external:after {
  content: url(../images/icon-external-link.png);
  margin: 0 0 0 5px;
}
/* End of external link styles */

.demoIntro {
  margin: 12px 0 12px 0;
  padding-left: 15px;
  padding-right: 15px;
}


/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 19px;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

/* Customize container */
@media (min-width: 768px) {
  .container {
    /*max-width: 730px;*/
    /*width: auto;*/
  }
}
.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}
.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}
.marketing p + h4 {
  margin-top: 28px;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

/* remedy for the conflict between bootstrap and angular on the empty href */
.nav, .pagination, .carousel a {
  cursor: pointer;
}


.left-arrow {
  border-color: transparent black;
  border-style: solid;
  border-width: 20px 20px 20px 0px;
  height: 0px;
  width: 0px;
}

.right-arrow {
  border-color: transparent black;
  border-style: solid;
  border-width: 20px 0px 20px 20px;
  height: 0px;
  width: 0px;
}

.down-arrow {
  border-color: black transparent;
  border-style: solid;
  border-width: 20px 20px 0px 20px;
  height: 0px;
  width: 0px;
}

.up-arrow {
  border-color: black transparent;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  height: 0px;
  width: 0px;
}

/* loading msg */
.loading {
  background-color: #458ac5;
  color: white;
  font-size: 24px;
  height: 60%;
  width: 70%;
  margin: 50px auto 50px auto;
  text-align: center;
  vertical-align: middle;
  padding: 25px;
}

.review-form {
  padding: 2rem;
}
.main-app .lf-form-view {
  margin: 15px 15px 15px 0;
}

/* Overrides needed due to styles from lforms */
.nav-links, .demoIntro, #initialMsg, .main-app .btn-group, #listAccordion {
  line-height: 1.42857143;  /* bootstrap 3 line height value */
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.nav-links a, .demoIntro a, #listAccordion .list-group-item a, h2 a {
  color: #337ab7;
  text-decoration: none;
}

#listAccordion {
  margin-bottom: 20px;
}

#listAccordion .accordion-button a:hover {
  color: inherit;
}

#listAccordion .accordion-body {
  padding: 0;
}

.accordion-item:not(:first-of-type) {
  margin-top: 5px;
}

.accordion-header > .accordion-button > a {
  color: #333;
}

.accordion-button {
  font-size: 16px;
}

.dropdown-item:hover {
  text-decoration: none;
}

html {
  font-size: 14px;
}

.nav-links a:focus, .nav-links a:hover, .demoIntro a:focus, .demoIntro a:hover,
#listAccordion a:focus, #listAccordion a:hover {
  color: #23527c;
  text-decoration: underline;
  animation-duration: inherit;
}

.nav-links a.current:hover {
  text-decoration: underline;
}
/* End of overrides needed due to styles from lforms */

/* The style for the "current" top-nav link (when the user is on that page). */
.nav-links a.current {
  font-weight: bold;
  text-decoration: none;
}

